import { Checkbox, Chip, Stack, Typography } from "@mui/material";
import { Investment } from "../../../model";
import { formatInvestmentAmount } from "../../../utils";
import { useSdkContext } from "../../../Sdk";
import TransactionSnapshotList from "../TransactionSnapshot/TransactionSnapshotList";

interface InvestmentItemProps {
    investment: Investment;
    onChange: (investment: Investment) => void;
}

const InvestmentItem = ({ investment, onChange }: InvestmentItemProps) => {
    const sdk = useSdkContext();
    const chipColor = investment.active?investment.transactionSnapshots.length>0?"primary":"success":"default";
    const textColor = investment.active?"text.primary":"text.disabled";

    const handleInvestmentChange = (active:boolean) => {
        investment.active = active;
        sdk.updateInvestment(investment);
        onChange(investment);
    }

    return  <Stack direction="row" gap={1} alignItems="center">
                <Checkbox defaultChecked={investment.active} onChange={(event) => handleInvestmentChange(event.target.checked)} size="small" sx={{p:0}} />
                <Typography component="span" key={investment.id} noWrap color={textColor}>{investment.name}</Typography>
                <Chip label={formatInvestmentAmount(investment)} color={chipColor} size="small" sx={{marginLeft:1}} />
                <Stack direction="row" gap={1} alignItems="center">
                    <TransactionSnapshotList investment={investment} transactions={investment.transactionSnapshots} enabled={investment.active} />
                </Stack>
            </Stack>;
}

export default InvestmentItem;