import { Grid2 as Grid, Stack } from "@mui/material";
import { Investment, Transaction } from "../../../model";
import TransactionAdd from "./TransactionAdd";
import TransactionItem from "./TransactionItem";
import { ReactNode } from "react";
import { grey } from "@mui/material/colors";

interface TransactionListProps {
    investment: Investment;
    transactions: Transaction[];
    onChange?: (transactions: Transaction[]) => void;
}

const TransactionList = ({ investment, transactions, onChange }: TransactionListProps) => {

    const handleTransactionChange = (transaction: Transaction) => {
    }
    
    const sortedTransaction = transactions?.toSorted((a,b)=> b?.tradeDate?.getTime() - (a?.tradeDate?.getTime()));
    const symbol = transactions[0]?.symbol;
    const Header = ({ children }: { children: ReactNode }) => <Grid size={2} bgcolor="lightgray" paddingX={1} paddingY={0.25}>{children}</Grid>;

    return <>
        <Grid container p={2} gap={1} maxHeight={400} overflow="auto">
            <Header>Date</Header>
            <Header>Quantity</Header>
            <Header>Price</Header>
            <Header>Total</Header>
            <Header>Type</Header>
            {sortedTransaction.map((snapshot) => 
                <TransactionItem key={snapshot.id} investment={investment} transaction={snapshot} onChange={handleTransactionChange} />
            )}
        </Grid>
    </>;
}

export default TransactionList;