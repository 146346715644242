import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Amount, Rate } from '../model/';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, Select, Stack } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { v4 as uuidv4 } from 'uuid';

interface AmountTextFieldProps {
    label: string;
    value: Amount | null | undefined;
    onChange: (value: Amount) => void;
    rates: Rate[] | undefined;
}

const AmountTextField: React.FC<AmountTextFieldProps> = ({ label, value, onChange, rates }) => {
    const currentAmount = value??{ id: uuidv4(), value: null, percentage: null, rate: null } as Amount;
    var initialUseState = 1;
    if(value?.value) initialUseState = 2;
    if(value?.percentage) initialUseState = 3;
    if(value?.rate) initialUseState = 4;

    const [useDate, setUseDate] = useState<number>(initialUseState);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        const newValue = parseFloat(event.target.value);
        const newAmount = {...currentAmount, value: newValue } as Amount;
        onChange(newAmount);
    };

    const handleIndexedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        const newAmount = {...currentAmount, indexed: newValue } as Amount;
        onChange(newAmount);
    }

    const handleOptionChange = (option: number) => {
        setUseDate(option);
        if (option===1) {
            onChange({...currentAmount, value: null, percentage: null, rate: null } as Amount);
        } else if (option===2) {
            onChange({...currentAmount, percentage: null, rate: null } as Amount);
        } else if (option===3) {
            onChange({...currentAmount, value: null, rate: null } as Amount);
        } else {
            onChange({...currentAmount, value: null, percentage: null } as Amount);
        }
    }

    const handleRateChange = (event: SelectChangeEvent<string>) => {    
        const newRateId = event.target.value as string;
        const newRate = rates?.find(rate => rate.id === newRateId) ?? null;
        const newAmount = {...currentAmount, rate: newRate } as Amount;
        onChange(newAmount);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={2}>
                <Stack direction="row">
                    <FormControlLabel control={<Radio size="small" />} label="No Amount" checked={useDate===1} onChange={handleOptionChange.bind(null, 1)} />
                    <FormControlLabel control={<Radio size="small" />} label="Use Amount" checked={useDate===2} onChange={handleOptionChange.bind(null, 2)} />
                    <FormControlLabel control={<Radio size="small" />} label="Use Percentage" checked={useDate===3} onChange={handleOptionChange.bind(null, 3)} />
                    <FormControlLabel control={<Radio size="small" />} label="Use Rate" checked={useDate===4} onChange={handleOptionChange.bind(null, 4)} />
                </Stack>
                {useDate!==1&&
                    <Stack direction="row">
                        <FormControlLabel control={<Checkbox size="small" onChange={handleIndexedChange} checked={value?.indexed} />} label="Should be indexed" />
                    </Stack>
                }
                {useDate===2 && 
                    <TextField
                        label={label}
                        type="number"
                        value={value?.value}
                        onChange={handleChange}
                    />
                }
                {useDate===3 && 
                    <TextField
                        label={label}
                        type="number"
                        value={value?.value}
                        onChange={handleChange}
                        
                    />
                }
                {useDate===4 && 
                    <FormControl fullWidth>
                        <InputLabel id="rate-label">Rate</InputLabel>
                        <Select
                            labelId="rate-label"
                            value={value?.rate?.id}
                            label="Rate"
                            onChange={handleRateChange}
                        >
                            {rates?.map(rate => <MenuItem key={rate.id} value={rate.id}>{rate.name} ({rate.value}%)</MenuItem>)}
                        </Select>
                    </FormControl>
                }
            </Stack>
        </LocalizationProvider>
    );
};

export default AmountTextField;