import { Stack, Typography } from "@mui/material";
import { Investment } from "../../../model";
import InvestmentItem from "./InvestmentItem";

interface InvestmentListProps {
    investments: Investment[];
    onChange: (investments: Investment[]) => void;
}

const InvestmentList = ({ investments, onChange }: InvestmentListProps) => {
    const onInvestmentChange = (investment: Investment) => {
        const index = investments.findIndex(i => i.id === investment.id);
        if (index >= 0) {
            investments[index] = investment;
            onChange([...investments]);
        }
    }
    
    const investmentItems = investments.sort((a, b) => (b?.amount?.value??0) - (a?.amount?.value??0)).map((investment, index) =>
        <InvestmentItem key={index} investment={investment} onChange={onInvestmentChange} />
    );

    return  <Stack>
                <Typography variant="body1">Investments:</Typography> 
                <Stack direction="column" gap={1} border={1} p={1} sx={{borderRadius: 2, borderColor: 'grey.500'}}>
                    {investmentItems}
                </Stack>
            </Stack>;
}

export default InvestmentList;