import { Button, Drawer } from "@mui/material";
import { Investment, Transaction } from "../../../model";
import { useState } from "react";
import TransactionEdition from "./TransactionEdition";

interface TransactionAddProps {
    investment: Investment;
    symbol?: string;
    onChange?: (transaction: Transaction) => void;
}

const TransactionAdd = ({ investment, symbol, onChange }: TransactionAddProps) => {
    const [open, setOpen] = useState(false);    
    const handleAdd = () => setOpen(true);
    const onClose = () => setOpen(false);
    const transaction = { symbol, tradeDate: new Date() } as Transaction;

    return <>
            <Button variant="contained" onClick={handleAdd}>Add</Button>
            <Drawer open={open} onClose={()=>setOpen(false)}>
                <TransactionEdition transaction={transaction} investment={investment} onChange={onChange} onClose={onClose} />
            </Drawer>
        </>;
}

export default TransactionAdd;