import React from 'react';
import { Box, Typography } from '@mui/material';

interface SplitBadgeProps {
  leftLabel?: React.ReactNode;
  centerLabel?: React.ReactNode;
  rightLabel?: React.ReactNode;
  chipColor?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
  badgeColor?: string; // Can accept any valid CSS color
  enabled?: boolean;
  fontSize?: string;
}

const SplitBadge: React.FC<SplitBadgeProps> = ({
  leftLabel,
  centerLabel,
  rightLabel,
  chipColor = 'primary',
  badgeColor = '#f0f0f0',
  enabled = true,
  fontSize = '6pt',
}) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 4,
        overflow: 'hidden',
        border: `1px solid`,
        borderColor: 'divider',
        height: 15,
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => enabled?theme.palette[chipColor]?.main:"text.disabled",
          color: (theme) => theme.palette[chipColor]?.contrastText,
          px: 1.5,
          py: 0.25,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2" fontSize={fontSize}>{leftLabel}</Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: badgeColor,
          px: 1.5,
          py: 0.25,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2" fontSize={fontSize}>{centerLabel}</Typography>
      </Box>

      {rightLabel && 
        <Box
          sx={{
            backgroundColor: (theme) => enabled?theme.palette[chipColor]?.main:"text.disabled",
            color: (theme) => theme.palette[chipColor]?.contrastText,
            px: 1.5,
            py: 0.25,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" fontSize={fontSize}>{rightLabel}</Typography>
        </Box>
      }
    </Box>
  );
};

export default SplitBadge;