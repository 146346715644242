import { useEffect, useState } from "react";

export function useLocalStorage<T>(key: string, fallbackValue: T) {
    const ls = window.localStorage;
    const [value, setValue] = useState<T>(
        () =>  JSON.parse(ls?.getItem(key) || JSON.stringify(fallbackValue))
    );
    useEffect(
        () => ls?.setItem(key, JSON.stringify(value)),
        [key, value]
    );
    return [value, setValue] as const;
}