import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App/App';
import Simulator from './App/ForexSimulator/Simulator';
import Profile from './App/Profile';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { SdkProvider, useSdkContext } from './Sdk';
import LoadingScreen from './App/LoadingScreen';
import { Button } from '@mui/material';

const ConfigWrapper = ({ app }: {app: React.ReactElement}) => {
  const sdk = useSdkContext();
  const handleClick = () => sdk.login();

  useEffect(() => {
    sdk.initialize()    
  }, []);
  
  return (
    <>
      {sdk.initialized && !sdk.authenticated && <Button onClick={handleClick}>Login</Button>}
      {sdk.initialized && sdk.authenticated && app}
      {sdk.loading && <LoadingScreen />}
    </>
  );
}

const RoutedApp = () => {
  const app = <App />;
  const forexsimulator = <Simulator />;
  const profile = <Profile />;

  const router = createBrowserRouter([
  {
      path: "/portfoliosimulator",
      element: <ConfigWrapper app={app} />,
  },
  {
      path: "/forexsimulator",
      element: <ConfigWrapper app={forexsimulator} />,
  },
  {
      path: "/profile",
      element: <ConfigWrapper app={profile} />,
  },
  {
      path: "/",
      element: <Navigate to="/portfoliosimulator" replace />,
  }
  ]);

  return  <SdkProvider>
              <RouterProvider router={router} />
          </SdkProvider>;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RoutedApp />
  </React.StrictMode>
);

reportWebVitals();
