import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import { Investor, Simulation } from "../../../model";
import React from "react";
import InvestorList from "./InvestorList"

interface InvestorButtonProps {
  simulation: Simulation | null;
  onSimulationChange: (simulation: Simulation) => void;
}

const InvestorButton: React.FC<InvestorButtonProps> = ({ simulation, onSimulationChange }) => {
    const [showInvestors, setShowInvestors] = React.useState<boolean>(false);
    const label = simulation === null ? "" : "(" 
      + simulation?.investors.flatMap(i => i.investments.filter(i2=>i.active && i2.active)).length 
      + "/" 
      + simulation?.investors.flatMap(i => i.investments).length 
      + ")";

    const handleInvestorsChange = (investors: Investor[]) => {
      const newSimulation = {...simulation} as Simulation;
      newSimulation.investors = investors;
      onSimulationChange(newSimulation);
    }

    return <>
        <Button variant="outlined" disabled={!simulation} onClick={()=>setShowInvestors(true)} >Investments {label}</Button>
        <Drawer open={showInvestors} onClose={()=>setShowInvestors(false)}>
          <Box position="relative" sx={{ position: 'absolute', top: 0, right: 0, m: 2 }}>
            <Stack direction="row" spacing={2}>
              <Button variant="contained"  onClick={() => setShowInvestors(false)}>Close</Button>
            </Stack>
          </Box>
          <Typography variant="h5" p={2}>Investors</Typography>
          <Box p={2}>
            <InvestorList investors={simulation?.investors??[]} onChange={handleInvestorsChange} />
          </Box>
        </Drawer>
      </>;
};

export default InvestorButton;