import { Drawer, IconButton } from "@mui/material";
import { Investment, Transaction } from "../../../model";
import { useState } from "react";
import { AddCircleRounded } from "@mui/icons-material";
import TransactionEdition from "../Transaction/TransactionEdition";

interface TransactionSnapshotAddProps {
    investment: Investment;
    enabled?: boolean;
    onChange?: (transaction: Transaction) => void;
}

const TransactionSnapshotAdd = ({ investment, enabled = true, onChange }: TransactionSnapshotAddProps) => {
    const [open, setOpen] = useState(false);
    const handleAdd = () => setOpen(true);
    const onClose = () => setOpen(false);
    const transaction = {} as Transaction;
    
    return <>
            <IconButton size="small" onClick={handleAdd} disabled={!enabled}>
                <AddCircleRounded fontSize="small" color={enabled?"primary":"disabled"} />
            </IconButton>
            {open && <TransactionEdition investment={investment} transaction={transaction} enabled={enabled} onChange={onChange} onClose={onClose} />}
        </>;
}

export default TransactionSnapshotAdd;