import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { Amount, Investment, Need, VariableDate } from "./model";

export const formatVariableDate = (date: VariableDate | null, defaultValue: string|undefined) => {    
  if(!date) return defaultValue;
  if(date.date) {
    return <Stack direction="row" alignItems="center">{formatDate(date.date)}</Stack>;
  }
  if(date.investor) {    
    return <Stack sx={{ whiteSpace: 'nowrap' }} direction="row">
        <Box>{date.age} yo</Box>
        <Chip label={date.investor.name} size="small" color="primary" sx={{ marginLeft: 1 }} />
      </Stack>
  }
  return defaultValue;
} 

export const formatVariableAmount = (amount: Amount | null) => {
  if(!amount) return '';
  if(amount.value) {
    return amount.value + "$";
  }
  if(amount.percentage) {
    return amount.percentage + "%";
  }
  if(amount.rate) {
    return  <Tooltip color="success" sx={{marginLeft:1}} title={amount.rate.name}>
              <Typography>{amount.rate.value + "%"}</Typography>
            </Tooltip>;
  }
  return '';
}


export function generateUId(): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let code = '';
  
  for (let i = 0; i < 9; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters[randomIndex];
      
      if ((i + 1) % 3 === 0 && i !== 8) {
          code += '-';
      }
  }
  
  return code;
}

export function resolveReferences(obj: any) {
  const references: { [key: string]: any } = {};

  function isIsoDate(value: string): boolean {
    // Extended check for ISO 8601 format
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;

    // Validate against the regex and ensure it parses to a valid date
    return isoDateRegex.test(value) && !isNaN(new Date(value).getTime());
  }

  function collectReferences(o: any) {
      if (o && typeof o === 'object') {
          if (o.$id) {
              references[o.$id] = o;
          }
          Object.keys(o).forEach(key => {
              collectReferences(o[key]);
          });
      }
  }

  function resolve(o: any, stack:any):any {
      if(stack.includes(o)) return o;
      stack.push(o);
      if (o && typeof o === 'object') {
          if (o.$values && Array.isArray(o.$values)) return o.$values.map((item: any) => resolve(item, stack));
          if (o.$ref) return resolve(references[o.$ref], stack);
          if (o.$id)  delete o.$id;
          Object.keys(o).forEach(key => {
              const value = o[key];
              if (typeof value === 'string' && isIsoDate(value)) {
                  o[key] = new Date(value);
              } else {
                  o[key] = resolve(value, stack);
              }
          });
      }
      return o;
  }

  collectReferences(obj);
  const resolved = resolve(obj,[]);
  return resolved;
}

export function serializeReferences(obj: any): string {
  const seenObjects = new Map<any, string>();
  let currentId = 1;

  function getId(o: any): string {
      if (!seenObjects.has(o)) {
          seenObjects.set(o, `${currentId}`);
          currentId++;
      }
      return seenObjects.get(o)!;
  }

  function isDate(value: any): boolean {
      return value instanceof Date && !isNaN(value.getTime());
  }

  function serialize(o: any): any {
      if (isDate(o)) {
          return o.toISOString().split('T')[0];
      }
      if (o && typeof o === 'object') {
          if (Array.isArray(o)) {
              if(seenObjects.has(o)){
                  return { $ref: getId(o) };
              }
              const id = getId(o);
              return { $id: id, $values: o.map(item => serialize(item)) };
          }
          if (seenObjects.has(o)) {
              return { $ref: getId(o) };
          }
          const serializedObject: any = { $id: getId(o) };
          Object.keys(o).forEach(key => {
              serializedObject[key] = serialize(o[key]);
          });
          return serializedObject;
      }
      return o;
  }

  return serialize(obj);
}


export const getAge = (birthString: string) => {
  const birth = new Date(birthString);
  const today = new Date();    
  const age = today.getFullYear() - birth.getFullYear() - 
              (today.getMonth() < birth.getMonth() || 
              (today.getMonth() === birth.getMonth() && today.getDate() < birth.getDate()) ? 1 : 0);
  return age;
}

export const formatCurrency = (amount: number | undefined | null) => {
  if(amount)
      return amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD', });
  return '';
}

export const formatInvestmentAmount = (investment: Investment) => {
  return investment.currentTotal?.toLocaleString('en-US', { style: 'currency', currency: 'USD', }) + " CAD";
}

export const formatNeedAmount = (need: Need) => {
  if(need.amount?.value)
    return need.amount.value?.toLocaleString('en-US', { style: 'currency', currency: 'USD', });
  if(need.amount?.percentage)
    return need.amount.percentage + "%";
  return '';
}

export const getVariableDateNumber = (variableDate: VariableDate | null): number => {
  if (variableDate) {
      if (variableDate.investor && variableDate.age) {
          const birthDate = new Date(variableDate.investor.birthDate);
          const futureDate = new Date(birthDate.getFullYear() + variableDate.age, birthDate.getMonth(), birthDate.getDate());
          return futureDate.getTime();
      } else if (variableDate.date) {
          return new Date(variableDate.date).getTime();
      }
  }
  return 0;
}

export const formatDate = (dateString: string|Date) => {
  if(dateString instanceof Date)
      return dateString.toLocaleDateString();
  const date = new Date(dateString);
  return date.toLocaleDateString();
}