import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { useSdkContext } from '../../Sdk';
import { Customer, Investor } from '../../model';
import InvestorList from './Investor/InvestorList';

const Profile = () => {
  const sdk = useSdkContext();
  const [customer, setCustomer] = useState<Customer|null>(null);
  
  useEffect(() => {
    const fetchMe = async () => {
      const me = await sdk.getMe();
      setCustomer(me);
    }
    fetchMe();
  }, []);

  const handleInvestorsChange = (investors: Investor[]) => {
    if (customer) {
      customer.investors = investors;
      setCustomer({...customer});
    }
  }

  const handleSave = async () => {
    if (customer) {
      await sdk.updateMe(customer);
    }
  }
  
  return (
    <div className="App">
      <Grid container spacing={1} padding={1}>
        <Typography variant="h5">{customer?.email}</Typography>
        <ButtonGroup>
          <Button variant="outlined" color="primary" onClick={() => sdk.logout()}>Logout</Button>
        </ButtonGroup>
      </Grid>
      <Grid container spacing={1} padding={1}>
        <InvestorList investors={customer?.investors??[]} onChange={handleInvestorsChange} />
      </Grid>
    </div>
  );
};

export default Profile;