import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { Investment, Transaction, TransactionSnapshot } from "../../../model";
import { useEffect, useState } from "react";
import { modalStyles } from "../../styles";
import { useSdkContext } from "../../../Sdk";
import TransactionList from "../Transaction/TransactionList";
import TransactionAdd from "../Transaction/TransactionAdd";
import SplitBadge from "../../Components/SplitBadge";
import { formatCurrency } from "../../../utils";

interface TransactionSnapshotEditionProps {
    investment: Investment;
    isNew?: boolean;
    transactionSnapshot?: TransactionSnapshot | null;
    enabled?: boolean;
    onChange?: (transaction: TransactionSnapshot) => void;
    onClose: () => void;
}

const TransactionSnapshotEdition = ({ investment, isNew = false, transactionSnapshot, enabled = true, onChange, onClose }: TransactionSnapshotEditionProps) => {
    const sdk = useSdkContext();
    const open = transactionSnapshot !== null || isNew;
    const buttonText = open ? "Add" : "Save";
    const [equityName, setEquityName] = useState<string>("");  
    const [current, setCurrent] = useState<TransactionSnapshot>(transactionSnapshot ?? {} as TransactionSnapshot);
    
    useEffect(() => {
        if(open && !isNew && transactionSnapshot) {
            const loadTransactions = async () => {
                const transactions = await sdk.readTransactions(investment.id, current.symbol);
                setEquityName(transactions[0]?.equity?.name || "");
                setCurrent({ ...transactionSnapshot, transactions });
            }
            loadTransactions();
        }
    }, [open]);

    const handleSave = () => {
        if(current && onChange) {
            onChange(current);
        }
    }

    const handleClose = () => {        
        setCurrent({} as TransactionSnapshot);
        onClose();
    }

    const handleTransactionsChange = (transactions: Transaction[]) => setCurrent({ ...current, transactions });
    const handleTransactionChange = (transaction: Transaction) => setCurrent({ ...current, transactions: [...current.transactions, transaction] });
    const centerLabel = formatCurrency(current.currentTotal) + " (" + current.quantity + " @ " + formatCurrency(current.currentPrice) + ") ";
    
    return <>
        <Modal open={open} onClose={handleClose}>
            <Box sx={modalStyles}>
                <Stack paddingX={1} direction="column" justifyContent="space-between">
                    <Typography variant="subtitle1" paddingX={1}>{equityName}</Typography>
                    <Box paddingX={1}>
                        <SplitBadge leftLabel={current.symbol} centerLabel={centerLabel} rightLabel={current.currency} enabled={enabled} chipColor="primary" fontSize="7pt" />
                    </Box>
                </Stack>
                <Stack>
                    {current.transactions && <TransactionList investment={investment} transactions={current.transactions} onChange={handleTransactionsChange} />}
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="flex-end" padding={1}>
                    <TransactionAdd investment={investment} onChange={handleTransactionChange} symbol={current?.symbol}/>
                    <Button variant="outlined"  onClick={handleClose}>Close</Button>
                </Stack>
            </Box>
        </Modal>
    </>;
}

export default TransactionSnapshotEdition;