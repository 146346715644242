import { Box, Button, Drawer, Modal } from "@mui/material";
import { Investment, TransactionSnapshot } from "../../../model";
import { formatCurrency } from "../../../utils";
import SplitBadge from "../../Components/SplitBadge";
import TransactionSnapshotEdition from "./TransactionSnapshotEdition";
import { useState } from "react";
import { modalStyles } from "../../styles";

interface TransactionSnapshotItemProps {
    investment: Investment;
    transaction: TransactionSnapshot;
    enabled?: boolean;
    onChange?: (transaction: TransactionSnapshot) => void;
}

const TransactionSnapshotItem = ({ investment, transaction, enabled = true, onChange }: TransactionSnapshotItemProps) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => setOpen(true);
    const handleClose = () => setOpen(false);    

    return  <>
            <Button variant="text" onClick={handleClick} style={{ padding: 0 }}>
                <SplitBadge leftLabel={transaction.symbol} centerLabel={formatCurrency(transaction.currentTotal)} enabled={enabled} chipColor="primary" fontSize="7pt" />
            </Button>
            {open && <TransactionSnapshotEdition investment={investment} onChange={onChange} onClose={handleClose} transactionSnapshot={transaction} />}
        </>;
}

export default TransactionSnapshotItem;