import { Box, Button, FormControl, FormControlLabel, InputLabel, Modal, Stack, TextField, Typography } from "@mui/material";
import { Investment, Transaction, VariableDate } from "../../../model";
import { ChangeEvent, useState } from "react";
import { modalStyles } from "../../styles";
import { useSdkContext } from "../../../Sdk";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface TransactionEditionProps {
    investment: Investment;
    transaction: Transaction;
    enabled?: boolean;
    onChange?: (transaction: Transaction) => void;
    onClose: () => void;
}

const TransactionEdition = ({ investment, transaction, enabled = true, onChange, onClose }: TransactionEditionProps) => {
    const sdk = useSdkContext();
    const isNew = transaction.id === undefined;
    const open = transaction !== null;
    const buttonText = open ? "Add" : "Save";
    const [current, setCurrent] = useState<Transaction>(transaction ?? {} as Transaction);

    const handleClose = () => {        
        setCurrent({} as Transaction);
        onClose();
    }

    const handleSave = async () => {
        isNew ? await sdk.createTransaction(investment.id, current) : await sdk.updateTransaction(current);
        onChange && onChange(current);
        handleClose();
    }

    const handleDelete = () => {
        if(current && onChange) {
            onChange(current);
        }
    }

    return <>
        <Modal open={open} onClose={handleClose}>
            <Box sx={modalStyles}>
                <Typography variant="subtitle1" p={2}>Add Transaction to {investment.name}</Typography>
                <Stack direction="column" spacing={2} p={2}>
                    <Stack direction="row" gap={2}>
                        <FormControl>
                            <TextField label="Symbol" value={current.symbol} onChange={(e) => setCurrent({ ...current, symbol: e.target.value })} />
                        </FormControl>
                        <FormControl>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Trade Date"
                                    value={dayjs(current?.tradeDate)}
                                    onChange={(date) => setCurrent({ ...current, tradeDate: dayjs(date).toDate() })}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Stack>
                    <Stack direction="row" gap={2}>
                        <FormControl>
                            <TextField label="Quantity" value={current.quantity} onChange={(e) => setCurrent({ ...current, quantity: parseFloat(e.target.value) })} />
                        </FormControl>
                        <FormControl>
                            <TextField label="Price" value={current.price} onBlur={(e) => setCurrent({ ...current, price: parseFloat(e.target.value) })} />
                        </FormControl>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={2} p={2} justifyContent="flex-end">
                    {!isNew && <Button variant="contained" onClick={handleDelete}>Delete</Button>}
                    <Button variant="contained" onClick={handleSave}>{buttonText}</Button>        
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>    
                </Stack>
            </Box>            
        </Modal>
    </>;
}

export default TransactionEdition;