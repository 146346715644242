import { Stack } from "@mui/material";
import { Investment, Transaction, TransactionSnapshot } from "../../../model";
import TransactionSnapshotAdd from "./TransactionSnapshotAdd";
import TransactionSnapshotItem from "./TransactionSnapshotItem";

interface TransactionSnapshotListProps {
    investment: Investment;
    transactions: TransactionSnapshot[];
    enabled?: boolean;
    onChange?: (transaction: Transaction) => void;
}

const TransactionSnapshotList = ({ investment, transactions, enabled = true, onChange }: TransactionSnapshotListProps) => {

    return (
        <Stack gap={1} direction="row" alignItems="center">
            {transactions.toSorted((a,b)=>b.currentTotal-a.currentTotal).map((snapshot) => 
                <TransactionSnapshotItem investment={investment} key={snapshot.symbol} transaction={snapshot} enabled={enabled} />
            )}
            <TransactionSnapshotAdd investment={investment} enabled={enabled} onChange={onChange} />
        </Stack>
    );
}

export default TransactionSnapshotList;