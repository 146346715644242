import { Investor } from "../../../model";
import InvestorItem from "./InvestorItem";

interface InvestorListProps {
    investors: Investor[];
    onChange: (investor: Investor[]) => void;
}

const InvestorList = ({ investors, onChange }: InvestorListProps) => {

    const onInvestorChange = (investor: Investor) => {
        const index = investors.findIndex(i => i.id === investor.id);
        if (index >= 0) {
            investors[index] = investor;
            onChange([...investors]);
        }
    }

    const orderedInvestors = investors.sort((a, b) => b.currentTotal - a.currentTotal);

    return  <div>
                {orderedInvestors.map(investor => <InvestorItem key={investor.id} investor={investor} onChange={onInvestorChange} />)}
            </div>;
}

export default InvestorList;        