import { Grid2 as Grid, Stack } from "@mui/material";
import { Button } from "@mui/material";
import { Investment, Transaction } from "../../../model";
import { formatCurrency, formatDate } from "../../../utils";
import SplitBadge from "../../Components/SplitBadge";
import { ReactNode, useState } from "react";
import TransactionEdition from "./TransactionEdition";

interface TransactionItemProps {
    investment: Investment;
    transaction: Transaction;
    enabled?: boolean;
    onChange?: (transaction: Transaction) => void;
}

const TransactionItem = ({ investment, transaction, enabled = true, onChange }: TransactionItemProps) => {
    const [transactionToEdit, setTransactionToEdit] = useState<Transaction | null>(null);
    const handleClick = () => setTransactionToEdit(transaction);
    const handleClose = () => setTransactionToEdit(null);    

    const rightLabel = <>
        {formatDate(transaction.tradeDate)} | 
        {transaction.quantity} @ {formatCurrency(transaction.price)}
    </>

    const Cell = ({ children }: { children: ReactNode }) => <Grid size={2} fontSize={14}>{children}</Grid>;

    return  <>
            <Cell>{formatDate(transaction.tradeDate)}</Cell>
            <Cell>{transaction.quantity}</Cell>
            <Cell>{formatCurrency(transaction.price)}</Cell>
            <Cell>{formatCurrency(transaction.quantity * transaction.price)}</Cell>
            <Cell>{transaction.type}</Cell>
            {transactionToEdit && <TransactionEdition investment={investment} onChange={onChange} onClose={handleClose} transaction={transactionToEdit} />}
        </>;
}

export default TransactionItem;