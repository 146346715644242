import { useLocalStorage } from "./localstorage";
import { Customer, Investment, Investor, Transaction } from "./model";
import { Simulation } from "./model/Simulation";
import { SimulationResult } from "./model/SimulationResult";
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { resolveReferences, serializeReferences } from "./utils";
import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';

const SdkContext = createContext<Sdk | undefined>(undefined);
export const SdkProvider = ({ children }: { children: ReactNode }) => {
    const sdk = useSdk();    
    return <SdkContext.Provider value={sdk}>{children}</SdkContext.Provider>;
};

export const useSdkContext = (): Sdk => {
    const context = useContext(SdkContext);
    if (context === undefined) {
        throw new Error("useSDKContext must be used within an SDKProvider");
    }
    return context;
};

export interface ISettings {
    version: string;
    apiversion: string,
    url_idp: string;
    url_api: string;
    idp_realm: string;
    idp_clientid: string;
}

export interface Sdk {
  settings: ISettings | null;
  loading: boolean;
  authenticated: boolean;
  initialized: boolean;
  initialize: () => Promise<void>;
  fetchSimulation: (simulationId: string) => Promise<Simulation>;
  runSimulation: (simulation: Simulation) => Promise<SimulationResult>;
  saveSimulation: (simulation: Simulation) => Promise<Simulation | null>;
  getMe: () => Promise<Customer>;
  updateMe: (customer: Customer) => Promise<Customer>;
  logout: () => void;
  login: () => void;
  register: () => void;

  updateInvestor: (investor: Investor) => Promise<Investor>;
  updateInvestment: (investment: Investment) => Promise<Investment>;

  createTransaction: (investmentId: string, transaction: Transaction) => Promise<Transaction>;
  readTransactions: (investmentId: string, symbol: string) => Promise<Transaction[]>;
  updateTransaction: (transaction: Transaction) => Promise<Transaction>;

}

interface AuthStorage {
  token: string;
  refreshToken: string;
  idToken: string;
  authenticated: boolean;
}

const useSdk = (): Sdk => {
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [lastError, setLastError] = useState<any>(null);
  const [authStore, setAuthStore] = useLocalStorage<AuthStorage>('keycloak', { authenticated: false } as AuthStorage);
  const [keycloak, setKeycloak] = useState<Keycloak>();
  const initializingPromise = useRef<Promise<Keycloak>>();

  useEffect(() => {
    if(!settings) return;
    const config: KeycloakConfig = { url: settings!.url_idp, realm: settings!.idp_realm, clientId: settings!.idp_clientid};
    const initOptions: KeycloakInitOptions = {
      token: authStore.token, refreshToken: authStore.refreshToken, idToken: authStore.idToken,
      enableLogging: true,
      timeSkew: 60,
      scope: 'openid email profile'
    };

    async function initKeyCloakClient() {
      const keycloakClient = new Keycloak(config);

      keycloakClient.onReady = () => {
        setLoading(false);
        setInitialized(true);
        console.dir('Keycloak Ready.');
      }

      keycloakClient.onAuthLogout = () => {
        setAuthStore({} as AuthStorage);
        console.dir('Keycloak Logged out.');
      }

      keycloakClient.onActionUpdate = () => {
        console.dir('Keycloak Action Update.');
      }

      keycloakClient.onAuthRefreshSuccess = () => {
        setAuthStore({ token: keycloakClient.token!, refreshToken: keycloakClient.refreshToken!, idToken: keycloakClient.idToken!, authenticated: true });
        console.dir('Keycloak Auth Token refreshed.');
      }

      keycloakClient.onAuthSuccess = () => {
        setAuthStore({ token: keycloakClient.token!, refreshToken: keycloakClient.refreshToken!, idToken: keycloakClient.idToken!, authenticated: true });
        console.dir('Keycloak Auth Success.');
      }

      keycloakClient.onAuthError = () => {
        setAuthStore({ authenticated: false } as AuthStorage);
        console.dir('Keycloak Auth Error.');
        window.location.reload();
      }

      keycloakClient.onAuthRefreshError = () => {
        setAuthStore({ authenticated: false } as AuthStorage);
        console.dir('Keycloak Auth Token refresh error.');        
      }

      keycloakClient.onTokenExpired = async () => {
        try{
          console.dir('Keycloak Auth Token expired, refreshing...');
          await keycloakClient.updateToken();
        }catch(e){
          console.dir('Keycloak Error refreshing token: ' + e);
        }
      };

      var result = await keycloakClient.init(initOptions);
      console.dir('Keycloak Client initialized. Authenticated:' + keycloakClient.authenticated + '. Result:' + result + '.');
              
      return keycloakClient;
    };

    if(!initializingPromise.current){
      initializingPromise.current = initKeyCloakClient();
    }

    initializingPromise.current?.then(keycloakClient => {
      setKeycloak(keycloakClient);
    });
    
  }, [keycloak, settings, authStore]);

  const initialize = async () => {
    const settingsData = await fetchSettings();
    setSettings(settingsData);
  };

  const fetchSettings = async (): Promise<ISettings> => {
    setLoading(true);    
    const response = await fetch('/settings.json');
    const data = await response.json();

    const gresponse = await fetch(data.url_api + '/api/version', { mode: 'cors' });
    const gversion = (await gresponse.json()).version;

    return {
      version: data.version,
      apiversion: gversion,
      url_idp: data.url_idp,
      url_api: data.url_api,
      idp_realm: data.idp_realm,
      idp_clientid: data.idp_clientid,
    };
  };

  const login = async () => { keycloak?.login(); };
  const logout = async () => { keycloak?.logout(); };
  const register = async () => { keycloak?.register(); };
  const fetchSimulation = async (simulationId: string): Promise<Simulation> => wrappedFetch(`/api/Simulation/${simulationId}`, null, true, 'GET', true);
  const runSimulation = async (simulation: Simulation): Promise<SimulationResult> => wrappedFetch('/api/Simulation/run', simulation, true, 'POST', true);
  const saveSimulation = async (simulation: Simulation): Promise<Simulation | null> => wrappedFetch(`/api/Simulation/${simulation.uId}`, simulation, true, 'PUT', true);
  const getMe = async (): Promise<Customer> => wrappedFetch('/api/me/profile', null, true, 'GET', true);
  const updateMe = async (customer: Customer): Promise<Customer> => wrappedFetch('/api/me/profile', customer, true, 'POST', true);
  const updateInvestor = async (investor: Investor): Promise<Investor> => wrappedFetch('/api/me/investor/?id='+investor.id, investor, true, 'POST', true);
  const updateInvestment = async (investment: Investment): Promise<Investment> => wrappedFetch('/api/me/investment/?id='+investment.id, investment, true, 'POST', true);
  
  const createTransaction = async (investmentId: string, transaction: Transaction): Promise<Transaction> => wrappedFetch(`/api/me/investment/transaction/create?id=${investmentId}`, transaction, true, 'POST', true);
  const readTransactions = async (investmentId: string, symbol: string): Promise<Transaction[]> => wrappedFetch(`/api/me/investment/transaction?id=${investmentId}&symbol=${symbol}`, null, true, 'GET', true);
  const updateTransaction = async (transaction: Transaction): Promise<Transaction> => wrappedFetch(`/api/me/investment/transaction?id=${transaction.id}`, transaction, true, 'POST', true);

  const wrappedFetch = async <T extends {} | null> (path:string, formData:any, authenticatedRequest: boolean = true, method: string = "GET", spinner: boolean = true): 
      Promise<T> => {      
      if(settings?.url_api === undefined) return null as T;
      setLoading(spinner);
      let result:T;
      try {
        const options = authenticatedRequest?
          {
              method: method,
              headers: {
                Authorization: `Bearer ${authStore.token}`,
                'Content-Type': 'application/json'
              }
            } as RequestInit
          :
          {
            method: method,
            headers: {
              'Content-Type': 'application/json'
            }
          } as RequestInit;

        if(formData){
          options.method = 'POST';
          options.body = JSON.stringify(serializeReferences(formData));
        }

        if(method !== 'GET')
          options.method = method;

        const response = await fetch(settings?.url_api + path, options);  

        if (!response.ok) {
          setLastError(response.statusText);
          console.error('Failed to submit request');
          throw new Error('Failed to submit request');          
        }
        if (response.status === 204)  
          return null as T;
        if (response.status === 401)
          setAuthStore({authenticated: false} as AuthStorage);

        if(response.headers.get('content-type')?.includes('application/json')){
          const json = await response.json();
          result = resolveReferences(json) as T;
        }
        else{
          result = null as T;
        }
      } catch (error) {
        setLastError(error);
        console.error('Error submitting form:', error);
        result = null as T;
      } finally {        
        setLoading(false);
      }
      return result;
    };

  return {
    settings,
    initialized,
    loading,
    authenticated: authStore.authenticated,
    initialize,
    fetchSimulation,
    runSimulation,
    saveSimulation,

    updateInvestor,
    updateInvestment,

    createTransaction,
    readTransactions,
    updateTransaction,
        
    getMe,
    updateMe,
    logout,
    login,
    register,
  };
};
