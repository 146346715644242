import { Checkbox, Chip, Stack, Typography } from "@mui/material";
import { Grid2 as Grid } from '@mui/material';
import { Investment, Investor } from "../../../model";
import { formatDate, formatInvestmentAmount, getAge } from "../../../utils";
import InvestmentList from "../Investment/InvestmentList";
import { useSdkContext } from "../../../Sdk";

interface InvestorItemProps {
    investor: Investor;
    onChange: (investor: Investor) => void;
}

const InvestorItem = ({ investor, onChange }: InvestorItemProps) => {
    const sdk = useSdkContext();

    const handleInvestmentChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        investor.active = event.target.checked;
        await sdk.updateInvestor(investor);
        onChange(investor);
    }

    const handleInvestmentsChange = (investments: Investment[]) => {
        const updatedInvestor = {...investor};
        updatedInvestor.investments = updatedInvestor.investments.map(i => investments.find(i2 => i2.id === i.id)??i);
        onChange(updatedInvestor);
    }

    const chipColor = investor.active? investor.investments.length>0?"primary":"success":"default";
    const textColor = investor.active? "text.primary":"text.disabled";

    return  <Grid container>
                <Grid size={1}>
                    <Checkbox defaultChecked={investor.active} onChange={handleInvestmentChange} size="small" sx={{p:0}} />
                </Grid>
                <Grid size={11}>
                    <Stack direction="row" gap={1} alignItems="center">
                        <Typography variant="h6" noWrap color={textColor}>{investor.name}</Typography>
                        <Typography variant="body2" paddingLeft={2} color={textColor}>{formatDate(investor.birthDate)}</Typography>
                        <Chip disabled={!investor.active} label={getAge(investor.birthDate) + " yo"} variant="outlined" size="small" color={chipColor}/>
                    </Stack>
                </Grid>
                <Grid size={1}/>
                <Grid size={11}>
                    <InvestmentList investments={investor.investments} onChange={handleInvestmentsChange} />
                </Grid>
            </Grid>;
}

export default InvestorItem;